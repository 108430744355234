<template>
  <div class="book-income-form">
    <div class="field">
      <label for="">Amount</label>
      <vs-input class="amount" v-model="amount"></vs-input>
    </div>
    <div class="field">
      <label for="">Date</label>
      <v-date-picker v-model="purchase_date" mode="date" ref="calendar">
        <template v-slot="{ inputValue, inputEvents }">
          <div>
            <vs-input
              class="calendar-input"
              :value="`${inputValue}`"
              v-on="inputEvents"
              size="large"
            />
          </div>
        </template>
      </v-date-picker>
    </div>
    <div class="field">
      <vs-select v-model="bookTypeId">
        <vs-select-item
          :key="bookType.id"
          :value="bookType.id"
          :text="bookType.name"
          v-for="bookType in bookTypes"
        />
      </vs-select>
    </div>
    <div class="field">
      <vs-textarea label="description" v-model="comment" />
    </div>
    <div class="save-popup-button">
      <vs-button icon="save" @click="saveIncome">Save</vs-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['transaction'],
  data() {
    return {
      amount: 0,
      comment: '',
      purchase_date: new Date(),
      id: null,
      bookTypeId: null,
    }
  },
  computed: {
    ...mapState({
      bookTypes: state => state.books.bookTypes,
    }),
  },
  methods: {
    saveIncome: function() {
      const { amount, comment, purchase_date, id, bookTypeId } = this
      this.$emit('saveIncome', {
        id,
        amount: parseInt(amount, 10),
        purchase_date: purchase_date.getTime() / 1000,
        comment,
        book_in_out_type_id: bookTypeId,
      })
    },
  },
  created: function() {
    setTimeout(() => {
      document.querySelector('.amount input').focus()
      if (this.transaction?.amount) {
        this.amount = this.transaction.amount
        this.comment = this.transaction.comment
        this.id = this.transaction.id
        this.purchase_date = new Date(this.transaction.purchase_date * 1000)
        this.bookTypeId = this.transaction.book_in_out_type_id
      }
    }, 50)
  },
}
</script>

<style lang="scss"></style>
