<template>
  <div class="books__list">
    <vs-table
      v-model="selected"
      search
      :data="books"
      @dblSelection="doubleClickHandler"
    >
      <template slot="header">
        <h3>Books ({{ books.length }})</h3>
      </template>
      <template slot="thead">
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="name">Tests count</vs-th>
        <vs-th></vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="tr.id" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].name">
            <vs-icon icon="import_contacts" />
            <span
              :style="{ backgroundColor: data[indextr].color }"
              class="group-color"
            ></span>
            {{ data[indextr].name }}
          </vs-td>
          <vs-td :data="data[indextr].schoolyear">{{
            data[indextr].tests_count || "-"
          }}</vs-td>

          <vs-td :data="data[indextr].id">
            <vs-button
              v-if="$can('manage', 'books')"
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="edit"
              type="flat"
              @click="editBookHandle(data[indextr].id)"
            ></vs-button>
            <vs-button
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="visibility"
              type="flat"
              @click="viewBookHandler(data[indextr].id)"
            ></vs-button>
            <vs-button
              v-if="$can('manage', 'books')"
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="delete"
              type="flat"
              @click="deleteBook(data[indextr].id)"
            ></vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selected: [],
      selectedId: null,
    };
  },
  computed: {
    ...mapState({
      books: (state) => state.books.books,
    }),
  },
  methods: {
    deleteBook: function (id) {
      this.selectedId = id;
      this.openAlert();
    },
    editBookHandle: function (id) {
      this.$emit("editBook", id);
    },
    doubleClickHandler: function (tr) {
      this.$router.push({ name: "Book", params: { book_id: tr.id } });
    },
    viewBookHandler: function (id) {
      this.$router.push({ name: "Book", params: { book_id: id } });
    },
    openAlert: function () {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Delete book",
        text: "Are you sure you want to delete the book?",
        accept: this.acceptAlert,
      });
    },
    acceptAlert: function () {
      this.$emit("deleteBook", this.selectedId);
      this.selectedId = null;
    },
  },
};
</script>

<style>
</style>