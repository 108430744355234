<template>
  <div>
    <div class="tab__header">
      <h2>Tests ({{ book.tests.length }})</h2>
      <div class="add-button" v-if="$can('manage', 'tests')">
        <vs-button icon="add" type="flat" @click="$emit('addTest')"
          >Add test</vs-button
        >
      </div>
    </div>

    <vs-list>
      <vs-list-item
        icon="colorize"
        :title="test.name"
        :subtitle="
          `max value: ${test.max_value}, results: ${
            test.results_count ? 'yes' : 'no'
          }`
        "
        v-for="(test, index) in book.tests"
        :key="index"
      >
      </vs-list-item>
    </vs-list>
  </div>
</template>

<script>
export default {
  props: ['book'],
}
</script>

<style lang="scss"></style>
