<template>
  <div class="book-view">
    <div class="status-bar">
      <div class="status-bar__back-button">
        <vs-button
          icon="arrow_back"
          class
          type="flat"
          color="dark"
          to="/books"
          title="back to books list"
        ></vs-button>
      </div>
      <h2 class="status-bar__title">
        <vs-icon icon="import_contacts"></vs-icon>
        {{ book.name }}
      </h2>
      <div class="book-view__owner owner" v-if="book.owner">
        <vs-avatar
          :src="
            book.owner.options && book.owner.options.avatar
              ? `${proxy}/static/files/${book.owner.options.avatar}`
              : undefined
          "
        ></vs-avatar>
        {{ book.owner.nickname }}
      </div>
      <div class="book-view__price" v-if="book.price">
        Price: <strong>{{ book.price }} р.</strong>
      </div>
      <div class="status-bar__manage-buttons" v-if="$can('manage', 'books')">
        <vs-button
          icon="edit"
          class="status-bar__edit-button"
          type="flat"
          color="dark"
          @click="popupActive = true"
          >Edit</vs-button
        >
      </div>
    </div>
    <div class="view-content">
      <Split>
        <SplitArea :size="65">
          <div class="panel-view" v-if="book.id">
            <div
              class="book-view__desc"
              style="white-space: pre-line"
              v-html="book.desc"
              v-if="book.desc"
            ></div>
            <vs-tabs>
              <vs-tab label="Tests" icon="colorize">
                <test-tab :book="book" @addTest="editBook" />
              </vs-tab>
              <vs-tab label="Groups" icon="peoples">
                <groups-tab :book="book" />
              </vs-tab>
              <vs-tab label="Amount" icon="payment">
                <amount-tab :book="book" />
              </vs-tab>
            </vs-tabs>
          </div>
        </SplitArea>
        <SplitArea :size="35">
          <vs-tabs>
            <vs-tab label="Comments">
              <div class="con-tab-ejemplo">
                <Comments :itemId="$route.params.book_id" itemType="Books" />
              </div>
            </vs-tab>
          </vs-tabs>
        </SplitArea>
      </Split>
    </div>
    <vs-popup
      class="form-popup books__popup"
      classContent="books__popup-content"
      :title="book.name"
      :active.sync="popupActive"
    >
      <BooksForm v-if="popupActive" :book="book" @closePopup="closePopup" />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BooksForm from '@/components/books/form.vue'
import TestTab from '@/components/books/testsTab.vue'
import GroupsTab from '@/components/books/groupsTab.vue'
import AmountTab from '@/components/books/amountTab.vue'

import Comments from '@/components/comments/comments.vue'

export default {
  name: 'BookView',
  title: 'SE-Book',
  data() {
    return {
      popupActive: false,
      id: this.$route.params.book_id,
      today: new Date(),
      proxy: process.env.VUE_APP_PROXY,
    }
  },
  components: {
    BooksForm,
    Comments,
    TestTab,
    GroupsTab,
    AmountTab,
  },
  computed: {
    ...mapState({
      book: state => state.books.singleBook,
    }),
  },
  created() {
    this.$store.dispatch('books/getSingleBookAction', {
      id: this.$route.params.book_id,
    })
  },
  methods: {
    editBook: function() {
      this.popupActive = true
    },
    closePopup: function() {
      this.popupActive = false
    },
  },
}
</script>

<style lang="scss">
.book-view__owner,
.book-view__price {
  margin-left: 15px;
}
.book-view__desc {
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 0.5rem 0;
}
.tab__header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 5px;
  h2 {
    margin-right: 1.5rem;
  }
}
</style>
