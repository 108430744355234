<template>
  <div class="book-groups-tab" id="loading">
    <div class="tab__header">
      <h2>Groups with book ({{ groupsBySingleBook.length }})</h2>
    </div>
    <div class="schoolyears">
      <vs-select width="300px" v-model="selectedYearId">
        <vs-select-item
          :key="year.id"
          :value="year.id"
          :text="year.name"
          v-for="year in years"
        />
      </vs-select>
    </div>
    <div class="book-groups">
      <div class="book-groups__filter field">
        <vs-checkbox v-model="showCurrentOnly">Show current books</vs-checkbox>
        <label for=""></label>
      </div>
      <div
        class="book-groups__item"
        v-for="(group, index) in groups"
        :key="index"
      >
        <Group :id="group.group_id" :color="group.color">{{
          group.name
        }}</Group>
        <div class="students-count">
          <strong>{{ group.students_count }}</strong> students
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Group from '@/components/common/elements/group'

export default {
  props: ['book'],
  data() {
    return {
      selectedYearId: null,
      showCurrentOnly: true,
    }
  },
  components: {
    Group,
  },
  computed: {
    ...mapState({
      currentYear: state => state.years.currentSchoolYear,
      years: state => state.years.years,
      groupsBySingleBook: state => state.books.groupsBySingleBook,
    }),
    groups: function() {
      if (this.showCurrentOnly) {
        return this.groupsBySingleBook.filter(group => {
          if (group.current_books?.book?.id == this.book.id) return group
          if (group.current_books?.reading?.id == this.book.id) return group
        })
      }
      return this.groupsBySingleBook
    },
  },
  methods: {
    getGroups: async function() {
      this.$vs.loading({
        container: '#loading',
        scale: 0.6,
      })
      if (this.book?.id && this.selectedYearId) {
        await this.$store.dispatch('books/getGroupsByBookAction', {
          id: this.book.id,
          schoolyear_id: this.selectedYearId,
        })
      }
      this.$vs.loading.close('#loading > .con-vs-loading')
    },
  },
  created: function() {
    this.selectedYearId = this.currentYear.id
    this.getGroups()
  },
  watch: {
    selectedYearId: function() {
      this.getGroups()
    },
  },
}
</script>

<style lang="scss">
.book-groups {
  margin-top: 1rem;
  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0.25rem;
    .group-element {
      width: 10rem;
    }
  }
  &__filter {
    padding: 0 5px;
    margin-bottom: 1.5rem;
  }
}
.schoolyears {
  position: absolute;
  top: 0;
  right: 1rem;
}
</style>
