<template>
  <div>
    <div class="book-amount-tab" id="loading">
      <div class="tab__header">
        <h2>Amount</h2>
        <div class="add-button" v-if="$can('manage', 'books')">
          <vs-button icon="add" type="flat" @click="popupActive = true"
            >Add in/out</vs-button
          >
        </div>
      </div>
      <div class="book-amount">
        <div class="book__transactions">
          <h4>History</h4>
          <div
            class="book__transaction"
            v-for="(transaction, index) in bookTransactions"
            :key="index"
          >
            <div class="book__transaction__amount">
              <div class="book__transaction__amount__value">
                <div v-if="transaction.amount > 0">+</div>
                <div class="amount">{{ transaction.amount }}</div>
                <vs-chip
                  class="book-type"
                  v-if="transaction.book_in_out_type_id"
                >
                  {{ transaction.book_in_out_type_name }}
                </vs-chip>
              </div>
              <div class="book__transaction__date">
                {{
                  $moment(transaction.date * 1000).format('YYYY-MM-DD HH:mm')
                }}
              </div>
            </div>
            <div class="book__transaction__group">
              <Group
                :id="transaction.id"
                :color="transaction.color"
                v-if="transaction.type == 'BookOut' && transaction.name"
                >{{ transaction.name }}</Group
              >
              <div
                v-else-if="transaction.comment"
                class="book__transaction__comment"
              >
                <vs-icon icon="comment" color="dark"></vs-icon>
                {{ transaction.comment }}
              </div>
            </div>
            <div
              class="book__transation__edit"
              v-if="transaction.type == 'BookIn'"
            >
              <vs-button
                icon="edit"
                type="flat"
                size="small"
                color="dark"
                @click="editIncomeHandler(transaction)"
              ></vs-button>
            </div>
            <div
              class="book__transation__delete"
              v-if="transaction.type == 'BookIn'"
            >
              <vs-button
                icon="delete"
                type="flat"
                size="small"
                color="dark"
                @click="deleteIncomeHandler(transaction.id)"
              ></vs-button>
            </div>
          </div>
        </div>
        <div class="book__stat">
          <h4>Statistic</h4>
          <div
            class="book__stat__item"
            v-for="(s, index) in statByBookType"
            :key="index"
          >
            <h5>{{ s.name }}</h5>
            <div class="book__stat__values">
              <div class="book__stat__value total">
                <div>Отстаток</div>
                <div class="value">{{ s.all }}</div>
              </div>
              <div class="book__stat__value total">
                <div>Бронь</div>
                <div class="value">{{ s.reserv }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vs-popup
      class="form-popup book-amount__popup"
      classContent="book_amount__popup-content"
      :title="book.name + ' income'"
      :active.sync="popupActive"
      v-if="popupActive"
    >
      <IncomeForm
        v-if="popupActive"
        :transaction="transaction"
        @closePopup="closePopup"
        @saveIncome="saveIncomeHandler"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Group from '@/components/common/elements/group'
import IncomeForm from './income/incomeForm.vue'
import eventBus from '@/eventBus.js'

export default {
  props: ['book'],
  data() {
    return {
      transaction: null,
      popupActive: false,
      bookTransactions: [],
      totalCount: 0,
      giveoutCount: 0,
      groupBooksCount: 0,
    }
  },
  components: {
    Group,
    IncomeForm,
  },
  computed: {
    ...mapState({
      currentYear: state => state.years.currentSchoolYear,
      groupsBySingleBook: state => state.books.groupsBySingleBook,
      bookTypes: state => state.books.bookTypes,
    }),
    statByBookType: function() {
      let output = []

      for (var key in this.totalCount) {
        const all =
          this.totalCount[key] -
          (this.giveoutCount[key] || 0) -
          (this.groupBooksCount[key] || 0)
        const reserv = this.groupBooksCount[key] || 0
        if (all || reserv) {
          output.push({
            name: this.bookTypes.find(b => b.id == key)?.name || 'Пусто',
            all,
            reserv,
          })
        }
      }
      output.push({
        // общая сумма (всего учебников минус отправленные на выдачу vbyec ,hjym)
        name: 'Всего',
        all:
          Object.values(this.totalCount).reduce(function(a, b) {
            return a + b
          }, 0) -
          Object.values(this.giveoutCount).reduce(function(a, b) {
            return a + b
          }, 0) -
          Object.values(this.groupBooksCount).reduce(function(a, b) {
            return a + b
          }, 0),
        reserv: (output.reserve = Object.values(this.groupBooksCount).reduce(
          function(a, b) {
            return a + b
          },
          0
        )),
      })

      return output
    },
  },
  methods: {
    getData: async function() {
      if (this.book?.id) {
        await this.$store
          .dispatch('books/getBookAmountsAction', {
            id: this.book.id,
          })
          .then(response => {
            const { data, meta } = response
            this.totalCount = meta.total_count
            this.groupBooksCount = meta.group_books_count
            this.giveoutCount = meta.giveout_count
            this.bookTransactions = data.map(d => {
              // цепляем тип прихода/расхода
              const book_in_out_type = this.bookTypes.find(
                b => b.id == d.book_in_out_type_id
              )
              d.book_in_out_type_name = book_in_out_type
                ? book_in_out_type.name
                : ''
              return d
            })
          })
      }
    },
    saveIncomeHandler: async function({
      id,
      amount,
      comment,
      purchase_date,
      book_in_out_type_id,
    }) {
      const data = {
        amount,
        comment,
        purchase_date,
        schoolyear_id: this.currentYear.id,
        book_in_out_type_id,
      }
      if (id) {
        data.id = id
      }
      await this.$store.dispatch('books/setBookIncomeAction', {
        id: this.book.id,
        data: [data],
      })
      await this.getData()
      this.closePopup()
      eventBus.$emit('updateCommentsList')
    },
    deleteIncomeHandler: function(incomeId) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete book',
        text: 'Are you sure you want to delete the book?',
        accept: async () => {
          await this.$store.dispatch('books/deleteBookIncomeAction', {
            incomeId,
          })
          await this.getData()
          eventBus.$emit('updateCommentsList')
        },
      })
    },
    editIncomeHandler: function(transaction) {
      this.transaction = transaction
      this.popupActive = true
    },
    closePopup: function() {
      this.transaction = null
      this.popupActive = false
    },
  },
  created: async function() {
    await this.$store.dispatch('books/getBookTypesAction')
    this.getData()
  },
  watch: {
    selectedYearId: function() {
      this.getData()
    },
  },
}
</script>

<style lang="scss">
.book-amount {
  display: flex;
  flex-flow: row nowrap;
  gap: 2rem;
  h4 {
    margin-left: 0.2rem;
    margin-top: 1rem;
  }
}
.book {
  .tab__header {
    position: relative;
  }

  &__stat {
    width: 300px;
    z-index: 1;
    &__item {
      padding: 0.5rem 1rem;
      border: 1px solid #eee;
      border-radius: 10px;
      margin: 0.5rem;
      box-shadow: 0 0 10px 0 #eee;
      h5 {
        font-size: 1;
      }
    }
    &__values {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      margin-top: 7px;
    }
    &__value {
      padding: 0.8rem;
      background-color: #f4f4f4;
      border-radius: 10px;
      flex: 1;
      .value {
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 0.8rem;
      }
    }
  }
  &__transactions {
    display: flex;
    flex-flow: column;
    width: 50%;
    flex: 1;
  }
  &__transaction {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0.7rem;
    border-radius: 10px;
    &:hover {
      background-color: #eee;
    }

    &__amount {
      font-size: 1.2rem;
      font-weight: bold;
      &__value {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .amount {
          flex: 1;
        }
      }
    }
    &__date {
      color: var(--sub-color);
      font-size: 0.8rem;
      font-weight: normal;
    }
    &__group {
      flex: 1;
      margin: 0 1rem;
    }
    &__comment {
      i {
        font-size: 1rem;
        opacity: 0.5;
      }
      font-size: 0.8rem;
    }
  }
  .book-type {
    padding: 0.5rem 1rem;
  }
}
</style>
