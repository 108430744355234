<template>
  <div class="books__form">
    <div class="students__form-content form-content-two-parts">
      <div class="inline-part left">
        <vs-input size="large" label="Name" v-model="name"></vs-input>
        <vs-input size="large" label="Price" v-model="price"></vs-input>
        <vs-textarea label="description" height="200px" v-model="desc" />
      </div>
      <div class="inline-part right">
        <div class="book-tests">
          <h3>Tests in book</h3>
          <draggable v-model="tests">
            <div
              class="book-tests__item list-item"
              v-for="(test, index) in tests"
              :key="index"
            >
              <vs-button
                icon="delete"
                type="flat"
                color="dark"
                @click="delTest(index)"
              ></vs-button>
              <vs-input
                size="large"
                label="Test name"
                v-model="tests[index].name"
                @keydown.enter="addTest"
                :ref="`name_${index}`"
              ></vs-input>
              <vs-input
                size="large"
                label="Max value"
                v-model="tests[index].max_value"
                @keydown.enter="addTest"
              ></vs-input>
              <input type="hidden" v-model="tests[index].id" />
            </div>
          </draggable>
          <div class="students__add-phone__button">
            <vs-button icon="add" type="flat" @click="addTest"
              >Add test</vs-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="save-popup-button">
      <vs-button icon="save" @click="saveGroup">Save</vs-button>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";

export default {
  name: "BookForm",
  props: ["book"],
  computed: {
    ...mapState({
      singleBook: (state) => state.books.singleBook,
    }),
  },
  components: {
    draggable,
  },
  data() {
    if (this.book.id) {
      return { ...this.book };
    }

    return {
      id: null,
      name: "",
      price: 0,
      tests: [],
    };
  },
  methods: {
    saveGroup: function () {
      let { id, name, price, desc } = this;
      let tests = this.tests.map((t, index) => {
        return { ...t, index };
      });
      const book = {
        id,
        name,
        price,
        desc,
        tests,
      };
      this.$store
        .dispatch("books/saveBookAction", { book })
        .then(() => {
          this.$vs.notify({
            title: "Book saved",
            text: `Book ${this.singleBook.name} saved`,
            color: "success",
            position: "top-right",
          });
          this.$emit("closePopup");
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: `${e}`,
            color: "danger",
            position: "top-right",
          });
        });
    },
    addTest: function () {
      if (this.tests == null) this.tests = [];
      const defaultTest = {
        name: "",
        max_value: "",
      };
      this.tests.push(defaultTest);
      const i = this.tests.length - 1;
      const index = `name_${i}`;
      setTimeout(() => {
        console.log(this.$refs[index]);
        this.$refs[index][0].focusInput();
      }, 50);
    },
    delTest: function (index) {
      this.tests.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.books__form {
  .vs-input {
    width: 100%;
    margin-bottom: 25px;
  }
  .con-select {
    width: 100%;
  }
}
.books__popup {
  .vs-popup {
    width: 60%;
  }
}
.list-item {
  margin: 10px 0;
  padding: 15px 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  box-shadow: 0 0 3px 1px #ccc;
  display: flex;
  flex-flow: row nowrap;
  gap: 15px;
}
</style>